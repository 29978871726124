.wrapper {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  height: calc(100vh - 64px);
  background: rgb(32,32,35);
  position: relative;
  padding-top: 64px;
}

.queue-picker {
  color: rgba(255,255,255,0.7);
  align-self: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 20px;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 14px;
  cursor: pointer;
}
.queue-picker.opened {
  background: rgba(255,255,255,0.2);
}

.queue-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin: 0 16px;
  flex-shrink: 0;
}

.queue-button {
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.7);
  cursor: pointer;
  font-size: 14px;
  margin: 4px;
}

.chart-wrapper {
  width: 100%;
  max-width: 500px;
  padding: 0 8px;
  margin: 0 16px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.chart-holder {
  position: relative;
  padding: 12px 20px 20px;
  width: 100%;
}

.number {
  position: absolute;
  color: rgba(255,255,255,.6);
  font-weight: 700;
  transform: translate(-50%, -50%);
}

.button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background: rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.7);
  font-weight: 700;
}
.button.set {
  position: absolute;
  top: 16px;
  right: 16px;
}
.button.outlined {
  border: 1px solid rgba(255,255,255,0.2);
  background: transparent;
  margin: 0 8px;
}

.input-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.input {
  background-color: rgba(255,255,255,0.3);
  margin-bottom: 24px;
  border: none;
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
}
